import { useState } from 'react'
import style from './ShortcutUrlGenerator.module.scss'
import { useTranslation } from 'react-i18next'
import { db } from '../../firebase'
import { setDoc, doc } from 'firebase/firestore'
import { isValidUrl, generateNanoId } from '../../utils/helpers'

import Metadata from '../../utils/metadata'

// 短網址生成器
function ShortcutUrlGenerator() {
  const { t } = useTranslation()
  const [originalUrl, setOriginalUrl] = useState('')
  const [shortUrl, setShortUrl] = useState('')
  const [isInvalidUrl, setIsInvalidUrl] = useState(true)
  // const [errorMessage, setErrorMessage] = useState('')

  // 生成短網址
  const generateShortUrl = async () => {
    while (true) {
      const shortCode = generateNanoId()

      try {
        await setDoc(
          doc(db, 'shortUrls', shortCode),
          {
            originalUrl,
            createdAt: new Date(),
          },
          { merge: true }
        )

        const domain = 'https://johnlin.me'
        setShortUrl(`${domain}/r/${shortCode}`)
        break
      } catch (error) {
        if (error.code === 'already-exists') {
          console.warn(`Collistion detected for ID: ${shortCode}`)
        } else {
          console.error('add short url error', error)
          break
        }
      }
    }
  }

  /**
   * 當輸入網址時，檢查是否為有效 URL
   * @param {*} e
   */
  const urlInputChange = (e) => {
    const url = e.target.value
    setOriginalUrl(url)
    // setErrorMessage('')

    if (!isValidUrl(url)) {
      setIsInvalidUrl(true)
    } else {
      setIsInvalidUrl(false)

      if (url.length < 35) {
        setIsInvalidUrl(true)
        // setErrorMessage(
        //   t('error.lengthTooShort', { ns: 'shortcutUrlGenerator' })
        // )
      }
    }
  }

  return (
    <>
      <Metadata
        title={`${t('title', { ns: 'shortcutUrlGenerator' })} | ${t('title', {
          ns: 'laboratory',
        })}`}
        description={t('description', { ns: 'shortcutUrlGenerator' })}
      />
      <div className={style.shortcutUrlGenerator}>
        {/* {errorMessage && <p>{errorMessage}</p>} */}
        <input
          type="text"
          value={originalUrl}
          onChange={urlInputChange}
          placeholder={t('content.inputUrl', { ns: 'shortcutUrlGenerator' })}
        />
        <button onClick={generateShortUrl} disabled={isInvalidUrl}>
          {t('content.generate', { ns: 'shortcutUrlGenerator' })}
        </button>
        {shortUrl && (
          <p>
            {t('content.shortUrl', { ns: 'shortcutUrlGenerator' })}:{' '}
            <a href={shortUrl} target="_blank" rel="noreferrer">
              {shortUrl}
            </a>
          </p>
        )}
      </div>
    </>
  )
}

export default ShortcutUrlGenerator
