import List from './ui/List/List'
import ContentBlock from './ui/ContentBlock/ContentBlock'

import { Helmet } from 'react-helmet-async'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './Portfolio.module.scss'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { db } from '../../firebase'
import { collection, getDocs } from 'firebase/firestore'
import useAuth from '../../hooks/useAuth'

function Portfolio() {
  const { t } = useTranslation()
  const { portfolioId } = useParams()
  const { isAdmin } = useAuth()
  const [portfolios, setPortfolios] = useState([])
  const [currentPortfolio, setCurrentPortfolio] = useState(null)

  // fetch projects from firestore
  useEffect(() => {
    const projectsCollection = collection(db, 'projects')
    const fetchProjects = async () => {
      const projectSnapshot = await getDocs(projectsCollection)
      const projectList = projectSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      setPortfolios(projectList)
    }
    fetchProjects()

    // set projects realtime listener
    // const unsubscribe = onSnapshot(projectsCollection, (snapshot) => {
    //   const updatedProjects = snapshot.docs.map((doc) => ({
    //     id: doc.id,
    //     ...doc.data(),
    //   }))
    //   setProjects(updatedProjects)
    // })
    // return () => unsubscribe()
  }, [])

  // if have projectId, set currentProject
  useEffect(() => {
    const project = portfolios.find((p) => p.id === portfolioId)
    setCurrentPortfolio(project)
  }, [portfolioId, portfolios])

  //* render content
  const renderContent = () => {
    if (!portfolioId) {
      return (
        <div className={style.project}>
          <Helmet>
            <title>
              {t('portfolio', { ns: 'header' })} |{' '}
              {t('title', { ns: 'header' })}
            </title>
            <meta
              name="description"
              content="探索 John 的網頁設計與開發專案作品集"
            />
            <meta
              property="og:title"
              content={`${t('portfolio', { ns: 'header' })} | ${t('title', {
                ns: 'header',
              })}`}
            />
            <meta
              property="og:description"
              content="探索 John 的網頁設計與開發專案作品集"
            />
            {/* <meta property="og:url" content="https://johnlin.me/projects" /> */}
          </Helmet>
          <List
            isAdmin={isAdmin}
            portfolios={portfolios}
            setPortfolios={setPortfolios}
          />
        </div>
      )
    }
    if (!currentPortfolio) {
      return (
        <div className={style.notFoundProject}>
          <FontAwesomeIcon icon={faCircleExclamation} />
          <h1>{t('portfolioNotFound', { ns: 'portfolio' })}</h1>
        </div>
      )
    }

    return (
      <ContentBlock
        portfolioId={portfolioId}
        currentPortfolio={currentPortfolio}
      />
    )
  }

  return <>{renderContent()}</>
}

export default Portfolio
