import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSpring, animated } from '@react-spring/web'
import style from './ScheduleDetectedModal.module.scss'
import type { ScheduleFormData } from '../Calendar/schedule'

interface ScheduleDetectedModalProps {
  isOpen: boolean
  onClose: () => void
  detectedSchedules: ScheduleFormData[]
  onConfirm: (selectedSchedules: ScheduleFormData[]) => void
}

/**
 * 檢測到的日程選擇模態框
 */
function ScheduleDetectedModal({
  isOpen,
  onClose,
  detectedSchedules,
  onConfirm,
}: ScheduleDetectedModalProps) {
  const { t } = useTranslation()
  const [selectedSchedules, setSelectedSchedules] = useState<Set<string>>(
    new Set()
  )

  const _spring_modal = useSpring({
    from: { opacity: 0, y: 50 },
    to: { opacity: 1, y: 0 },
    config: { duration: 500 },
  })

  // 處理日程選擇
  const handleScheduleToggle = useCallback((scheduleId: string) => {
    setSelectedSchedules((prev) => {
      const newSet = new Set(prev)
      if (newSet.has(scheduleId)) {
        newSet.delete(scheduleId)
      } else {
        newSet.add(scheduleId)
      }
      return newSet
    })
  }, [])

  // 處理確認
  const handleConfirm = useCallback(() => {
    const selected = detectedSchedules.filter((schedule) =>
      selectedSchedules.has(schedule.id)
    )
    onConfirm(selected)
    onClose()
  }, [detectedSchedules, selectedSchedules, onConfirm, onClose])

  if (!isOpen) return null

  return (
    <div className={style.modal_overlay}>
      <animated.div className={style.modal} style={_spring_modal}>
        <div className={style.modal_header}>
          <h2>{t('detected_schedules', { ns: 'schedule' })}</h2>
          <button className={style.close_button} onClick={onClose}>
            ✕
          </button>
        </div>

        <div className={style.modal_content}>
          <div className={style.schedule_list}>
            {detectedSchedules.map((schedule) => (
              <div
                key={schedule.id}
                className={`${style.schedule_item} ${
                  selectedSchedules.has(schedule.id) ? style.selected : ''
                }`}
                onClick={() => handleScheduleToggle(schedule.id)}
              >
                <div className={style.schedule_title}>{schedule.title}</div>
                <div className={style.schedule_time}>
                  {schedule.startTime.toLocaleString()} -{' '}
                  {schedule.endTime.toLocaleString()}
                </div>
                {schedule.description && (
                  <div className={style.schedule_description}>
                    {schedule.description}
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className={style.button_group}>
            <button
              className={style.confirm_button}
              onClick={handleConfirm}
              disabled={selectedSchedules.size === 0}
            >
              {t('confirm.add_selected', { ns: 'schedule' })}
            </button>
            <button className={style.cancel_button} onClick={onClose}>
              {t('button.cancel', { ns: 'common' })}
            </button>
          </div>
        </div>
      </animated.div>
    </div>
  )
}

export default ScheduleDetectedModal
